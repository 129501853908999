<template>
  <div>
    <v-row class="mt-3" no-gutters align="center" justify="center">
      <v-col cols="6">
        <v-card flat>
          <v-card-text class="d-flex justify-center pa-0">
            <!-- <template v-if="getKioskProfile.data().physicalKeyboard">
            <v-text-field placeholder="Enter a form name to search for Form" class="text-h5" hide-details outlined
              height="55px" rounded v-model="searchKeyword" :append-icon="appendIcon"
               @click:append="searchKeyword = ''; expand = false">
            </v-text-field>
          </template>
          <template v-else>
            <v-text-field placeholder="Enter a form name to search for Form" class="text-h5" hide-details outlined
              height="55px" rounded v-model="searchKeyword" @click="expand = !expand" :append-icon="searchBarIcon"
              clear-icon="mdi-close-circle" @click:append="searchKeyword = ''; expand = false">
            </v-text-field>
            
            <v-icon size="35" class="ml-2" v-if="!expand" @click="expand = !expand">mdi-keyboard-outline</v-icon>
            <v-icon size="35" class="ml-2" v-else @click="expand = !expand">mdi-keyboard-off-outline</v-icon>
          </template> -->


            <v-text-field placeholder="Enter a form name to search for Form" class="text-h5" hide-details outlined
              height="55px" rounded v-model="searchKeyword" @click="updateExpand()" :append-icon="searchBarIcon"
              clear-icon="mdi-close-circle" @click:append="clearSearch()">
            </v-text-field>
            <template v-if="!getKioskProfile.data().physicalKeyboard">
              <v-icon size="35" class="ml-2" v-if="!expand" @click="expand = !expand">mdi-keyboard-outline</v-icon>
              <v-icon size="35" class="ml-2" v-else @click="expand = !expand">mdi-keyboard-off-outline</v-icon>
            </template>

          </v-card-text>
          <v-expand-transition>
            <v-card-text class="d-flex justify-center ma-0 pa-0" v-if="expand">
              <SimpleKeyboard class="keyboard" @onKeyPress="onKeyPress" @onChange="onChange">
              </SimpleKeyboard>
            </v-card-text>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters class="ma-2">
          <v-col cols="12" align="center" v-if="expand || searchKeyword !== ''">
            <v-card v-if="expand || searchKeyword !== ''" :ripple="false" class="containerBorder" width="95%" flat
              :height="cardHeight">
              <v-card-text v-if="matchingForms.length === 0" class="pa-0">
                <span class="text-h4"> No matching forms found. </span>
              </v-card-text>
              <div v-else>
                <v-card flat :height="cardHeight - 10" class="overflow-y-auto">
                  <v-row no-gutters class="mt-5 ml-6">
                    <v-col cols="4" v-for="(form, i) in matchingForms" :key="i">
                      <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="displayDoc(form)" width="90%" height="100">
                        <v-row no-gutters class="fill-height" align="center" v-if="(form.documentType !== 'eforms')">
                          <v-col cols="2" align="center">
                            <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                          </v-col>
                          <v-col cols="10">
                            <v-card-title class="text-subtitle-1 font-weight-bold justify-center text-wrap">
                              {{ form.formName | trimLength }}
                            </v-card-title>
                          </v-col>
                        </v-row>
                        <v-row v-else class="fill-height" no-gutters align="center">
                          <v-col cols="2">
                            <v-img src="@/assets/eforms.png" contain height="70"></v-img>
                          </v-col>
                          <v-col cols="10">
                            <v-card-title class="text-subtitle-1 font-weight-bold justify-center text-wrap">
                              {{ form.formName | trimLength }}
                            </v-card-title>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12" v-else>
            <v-row>
              <v-col cols="3" class="mt-5" align="center">
                <v-card width="95%" class="containerBorder" :height="cardHeight">
                  <v-card-title class="justify-center primary--text">{{ $t('categoryTitle') }}</v-card-title>
                  <v-divider class="mx-3"></v-divider>
                  <v-list class="overflow-y-auto" :height="cardHeight - 80">
                    <v-list-item v-for="(categoryType, i) in $t('formCategory')" :key="i">
                      <v-list-item-content>
                        <v-btn height="45"
                          @click="$store.commit('setSelectedFormCat', { category: categoryType.categoryId })"
                          v-bind:color="getSelectedFormCat.category === categoryType.categoryId ? 'secondary' : 'primary'">{{
                            categoryType.name
                          }}</v-btn>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>

              <v-col cols="9" class="mt-5" align="center">
                <v-card width="95%" class="containerBorder" :height="cardHeight">
                  <v-tabs v-model="tab">
                    <v-tab v-for="(categoryType, i) in getCategoryType" :key="i"> {{ categoryType.type }} </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(categoryType, i) in getCategoryType" :key="i">
                      <v-card flat :height="cardHeight - 80" class="overflow-y-auto">
                        <v-row no-gutters class="mt-2 ml-6">
                          <v-col cols="4" v-for="(form, i) in getForms(categoryType)" :key="i">
                            <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="displayDoc(form)" width="90%"
                              v-if="(categoryType.type !== 'E-Forms')" height="100">
                              <v-row no-gutters class="fill-height" align="center">
                                <v-col cols="2" align="center">
                                  <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                                </v-col>
                                <v-col cols="10">
                                  <v-card-text class="text-subtitle-1 font-weight-bold">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card>
                            <v-card v-else class="mt-2 mb-2 mx-1 PDFborder" flat :href="form.documentUrl" width="90%"
                              height="100">
                              <v-row class="fill-height" no-gutters align="center">
                                <v-col cols="2">
                                  <v-img src="@/assets/eforms.png" contain height="70"></v-img>
                                </v-col>
                                <v-col cols="10">
                                  <v-card-text class="text-subtitle-1 font-weight-bold">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                        <!-- <v-row no-gutters class="mt-2 ml-6">
                          <v-col cols="4" v-for="(form, i) in getForms(categoryType)" :key="i">
                            <v-card class="mt-2 mb-2 mx-1 PDFborder" flat @click="displayDoc(form)" width="90%"
                              height="100">
                              <v-row no-gutters class="fill-height" align="center"
                                v-if="(categoryType.type !== 'E-Forms')">
                                <v-col cols="2" align="center">
                                  <v-img src="@/assets/pdf.png" contain height="70"></v-img>
                                </v-col>
                                <v-col cols="10">
                                  <v-card-text class="text-subtitle-1 font-weight-bold">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                              <v-row v-else class="fill-height" no-gutters align="center" :href="form.documentUrl">
                                <v-col cols="2">
                                  <v-img src="@/assets/eforms.png" contain height="70"></v-img>
                                </v-col>
                                <v-col cols="10">
                                  <v-card-text class="text-subtitle-1 font-weight-bold">
                                    {{ form.formName | trimLength }}
                                  </v-card-text>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row> -->
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";
export default {
  name: "forms",
  data() {
    return {
      expand: false,
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      cardHeight: 685,
      tab: 0
    };
  },
  components: {
    SimpleKeyboard,
  },
  computed: {
    ...mapGetters(["getQnaKeyword", "getSelectedFormCat", "getFormCategories", "getKioskProfile", "getAvatarLangCodes"]),
    getCategoryType() {
      let temp = []
      this.$t('formCategory').forEach(type => {
        if (type.categoryId === this.getSelectedFormCat.category) {
          temp = type.categoryType
        }
      })
      console.log('Categories: ', temp)
      return temp.sort((a, b) => a.type.localeCompare(b.type))
    },
    matchingForms() {
      //return [];
      return this.$i18n
        .t("forms")
        .filter((form) =>
          form.formName.toLowerCase().includes(this.searchKeyword.toLowerCase())
        );
    },
    appendIcon() {
      return this.searchKeyword ? 'mdi-close-circle' : 'mdi-magnify';
    },
  },
  watch: {
    expand(newVal) {
      if (this.expand === false) {
        this.searchKeyword = ''
      }
      if (newVal) {
        this.searchBarIcon = "mdi-close-circle";
        if (this.$store.getters.getKioskProfile.data().physicalKeyboard) {
          this.cardHeight = 685
        }
        else {
          this.cardHeight = 450;
        }
      } else {
        if (this.searchKeyword !== "") {
          this.searchBarIcon = "mdi-close-circle";
        } else {
          this.searchBarIcon = "mdi-magnify";
        }
        this.cardHeight = 685;
      }
    },
    getQnaKeyword(newVal) {
      this.searchKeyword = newVal
    },
    searchKeyword(newVal) {
      // if (newVal === "") {
      //   this.searchBarIcon = "mdi-magnify";
      // }


      if (newVal === "") {
        this.searchBarIcon = "mdi-magnify";
        // this.colLenght = 12;
      } else {
        this.searchBarIcon = "mdi-close-circle";
        // this.colLenght = 12;
      }
    }
  },
  methods: {
    updateExpand() {
      if (this.getKioskProfile.data().physicalKeyboard) {
        this.expand = false
      } else {
        if (this.expand) {
          this.expand = false
        } else {
          this.expand = true
        }
      }
    },
    clearSearch() {
      this.searchKeyword = "";
      this.$store.commit("setClearInput", true)
    },
    displayDoc(form) {
      this.$store.commit('setRequestQna', false)
      this.$store.commit("setIsThinking", false)
      this.$store.commit("setSpeechRecognitionHandler", false);
      if (form.documentType !== 'eforms') {
        this.$store.dispatch('openPdfViewer', {
          url: form.document,
          name: form.formName,
          emailLink: form.documentUrl,
          type: form.documentType
        })
      } else {
        this.$store.dispatch('openEmailViewer', {
          pdfType: form.documentType,
          pdfName: form.formName,
          pdfUrl: form.documentUrl
        })
      }
    },
    sendLink(url, name) {
      //this.$store.dispatch("avatarSpeak", "Please enter your Email ID to get this link.");
      this.$store.commit('setTextBubbleText', "Please enter your Email ID to get this link.");
          window.sayText(
            "Please enter your Email ID to get this link.",
            this.getAvatarLangCodes.voiceId,
            this.getAvatarLangCodes.langId,
            this.getAvatarLangCodes.engineId
          )
      this.$store.dispatch("openEmailViewer", {
        pdfType: "link",
        pdfUrl: url,
        pdfName: name,
      });
      this.$store.commit("setTouchRequest", {
        module: "Online Services",
        action: "Open Email Viewer",
        response: "name",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    getForms(category) {
      let temp = []
      let faqCats = this.getFormCategories.filter(category => category.locale === "en")
      let selectedFaqCat = faqCats.filter(cat => this.getSelectedFormCat.category === cat.categoryId)[0].name
      this.$t('forms').forEach(form => {
        if (form.formCategory === selectedFaqCat) {
          temp.push(form)
        }
      })

      if (category.type === 'E-Forms') {
        //console.log()
        return temp.filter(form => form.documentType === 'eforms');
      } else {
        return temp.filter(form => form.documentType === category.type.toLowerCase());
      }

    },
    onChange(input) {
      this.searchKeyword = input;
    },
    onKeyPress(button) {
      this.$store.commit("setClearInput", false)
      if (button === "{bksp}")
        this.searchKeyword = this.searchKeyword.slice(0, -1);
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 65) {
        return val;
      }
      return `${val.substring(0, 60)}...`;
    },
  },
  mounted() {
    this.searchKeyword = this.getQnaKeyword
    // if (this.searchKeyword !== '') {
    //   this.colLenght = 12
    // }
  }
};
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}

.containerBorder1 {
  border-width: 2px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 8px !important;
}

.text-wrap {
  overflow-wrap: anywhere;
}
</style>

<i18n>
{
  "en": {
      "cardHeader1": "Email links to Online Services",
      "cardHeader2": "Forms: Marriage Application, Notary and CPL",
      "link1": "Email link",
      "alter": "OR",
      "categoryTitle": "CATEGORIES"
    },
  "es":{
      "cardHeader1": "Servicios en Línea",
      "cardHeader2": "Formas: Marriage Application, Notary and CPL",
      "link1": "Email link",
      "alter": "O",
      "categoryTitle": "Categorías"
    }
}
</i18n>
