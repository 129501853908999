<template>
  <v-dialog
      v-model="getCourtTimingViewer.show"
      @click:outside="$store.commit('closeCourtTimingViewer')"
      width="40%"
      persistent>

    <v-card class="pa-5">
      <div style="position: absolute; align-self: end; right: 0; top: 0">
        <v-btn
            icon
            @click="$store.commit('closeCourtTimingViewer')"
            class="mr-5 mt-3">
          <v-icon color="red" x-large>mdi-window-close</v-icon>
        </v-btn>
      </div>
        <span
            class="text-h4 text--black">
          Taos County Courthouse
        </span>
      <br/>
      <span>
          Clerk's Office Hours: Monday through Friday, 8 AM - 4 PM.
        </span>
      <br/>
      <br/>
      <span
          class="text-h5">
          Addresses
        </span>
      <br>
      <br>
      <span
          class="text-h6">
          Physical Address
        </span>
      <br>
      <span>
          Eighth Judicial District Court <br> 105 Albright St., Suite N <br> Taos, NM 87501
        </span>
      <br>
      <br>
      <span
          class="text-h6">
        Contact
      </span>
      <br>
      <span>
        Phone: 505-455-8250 <br> Fax: 575-751-1281
      </span>

    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "courtTimingViewer",
  computed: {
    ...mapGetters(['getCourtTimingViewer'])
  }
}
</script>

<style scoped>

</style>
