<template>
  <div>
    <v-row class="mt-7" no-gutters align="center" justify="center">
      <v-col cols="12" align="center">
        <v-card height="675" class="containerBorder" flat width="95%">
          <v-row class="pa-5" no-gutters>
            <v-col cols="3" class="pa-3">
              <v-card height="95" flat color="primary" @click="navigateToPage()">
                <v-row no-gutters class="fill-height" align="center">
                  <v-col cols="12" align="center">
                    <span class="text-h6 white--text">
                      Courtroom Locations
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col v-for="(department, index) in $t('departments')" :key="index" class="pa-3" cols="3">
              <v-card height="95" flat color="primary" @click="viewMap(department)">
                <v-row no-gutters class="fill-height" align="center">
                  <v-col cols="12" align="center">
                    <span class="text-h6 white--text">
                      {{ department.departmentName | trimLength }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: "departments",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 560,
    };
  },
  computed: {
    ...mapGetters(['getAvatarLangCodes'])
  },
  methods: {
    viewMap(department) {
      console.log('Department : ', department)
      this.$i18n.t("maps").forEach((mapper) => {
        if (mapper.mapName.toLowerCase().trim() === department.departmentMap.toLowerCase().trim()) {
          this.$store.commit("setDepartmentServiceViewer", {
            show: true,
            departmentName: department.departmentName,
            departmentMap: mapper.mapImage,
            services: department.departmentServices.departments.deptCategory
          })
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1;
        } else {
          console.log('Department not found')
        }
      });
    },
    navigateToPage() {
      this.$router.push('/todayshearing');
      this.$store.commit('setPageHeader', 'Courtroom & Record Search');
      this.$store.commit('setTextBubbleText', 'Look up your case or find your courtroom location.');
      window.sayText(
        'Look up your case or find your courtroom location.',
        this.getAvatarLangCodes.voiceId,
        this.getAvatarLangCodes.langId,
        this.getAvatarLangCodes.engineId
      )
    }
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() {
    console.log('Departments: ', this.$i18n.t('departments'))
  },
};
</script>

<style>
.PDFborder {
  border-style: solid;
  border-width: 2px;
  border-color: #f57c00 !important;
  overflow-y: scroll;
  border-radius: 15px !important;
}
</style>
