
module.exports = {
  firebase: {
    apiKey: "AIzaSyBusDyE8kqozwHfmpE-Cxbch5NhcO7oRgs",
    authDomain: "el-paso-5508e.firebaseapp.com",
    databaseURL: "https://el-paso-5508e-default-rtdb.firebaseio.com",
    projectId: "el-paso-5508e",
    storageBucket: "el-paso-5508e.appspot.com",
    messagingSenderId: "40989674308",
    appId: "1:40989674308:web:9a1fc8dac39bc259662511",
    measurementId: "G-8T2EC604KW"
  }
}

