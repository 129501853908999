<template>
    <div>
        <v-row class="mt-7" no-gutters align="center" justify="center">
            <v-col cols="12" align="center">
                <v-card height="675" class="containerBorder" flat width="95%">
                    <v-row no-gutters class="fill-height">
                        <v-col cols="3" align-self="center">
                            <v-card width="80%" height="350" @click="$store.commit('setPaymentOptionViewer', true)"
                                color="primary" class="d-flex flex-column align-center justify-center">
                                <v-card-title class="text-h5 font-weight-bold white--text">
                                    Juvenile Payments
                                </v-card-title>
                                <v-chip class="px-15" large>Tap to Pay</v-chip>
                            </v-card>
                        </v-col>
                        <v-col cols="3" align-self="center">
                            <v-card width="80%" height="350" href="https://tx.smartchildsupport.com/main.aspx"
                                color="primary" class="d-flex flex-column align-center justify-center">
                                <v-card-title class="text-h5 font-weight-bold white--text">
                                    Child Support Payments
                                </v-card-title>
                                <v-chip class="px-15" large>Tap to Pay</v-chip>
                            </v-card>
                        </v-col>
                        <v-col cols="6" align-self="center">
                            <v-card width="70%" height="550" class="taxOfficeCard" flat>
                                <v-card-title class="text-h5 d-flex align-center justify-center font-weight-bold primary--text">
                                    Tax Office Portal
                                </v-card-title>
                                <v-card-actions class="justify-center pa-0 ma-0">
                                    <qrcode-vue :value="value" :size="size" level="H" class="pt-5"></qrcode-vue>
                                </v-card-actions>
                                <v-card-text class="text-subtitle-1 font-weight-bold primary--text">Scan the QR code to access Tax Office Portal</v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QrcodeVue from 'qrcode.vue'
export default {
    name: "payments",
    components: {
        QrcodeVue
    },
    data(){
        return{
            value: 'https://apps.epcountytx.gov/taxofficeportal/Identity/Account/Login',
            size: 385
        }
    }
}
</script>
<style>
.taxOfficeCard{
    border: solid 3px #2c6b87 !important;
}
</style>