<template>
  <v-card flat class="d-flex align-center justify-center" height="750">
    <v-row no-gutters class="fill-height">
      <v-col cols="7" align-self="center" align="center">
        <v-card height="750" width="90%" flat class="cardCourtroom">
          <v-row no-gutters>
            <v-col cols="3" class="font-weight-bold text-decoration-underline mt-3 text-h6">
              Courtroom
            </v-col>
            <v-col cols="2" class="font-weight-bold text-decoration-underline mt-3 text-h6">
              Courtroom Alias
            </v-col>
            <v-col cols="2" class="font-weight-bold text-decoration-underline mt-3 text-h6">
              Courtroom Floor
            </v-col>
            <v-col cols="5" class="font-weight-bold text-decoration-underline mt-3 text-h6">
              Courtroom Location
            </v-col>
          </v-row>
          <v-card height="680" class="overflow-y-auto" flat>
            <v-card-title class="d-flex align-center justify-center text-decoration-underline">District Courts</v-card-title>
            <v-card-text v-for="(court, i ) in getCourtroomsList('DC')" :key="i" class="pa-0">
              <v-row no-gutters>
                <v-col cols="3" align-self="center" class="font-weight-bold">{{ court.mapName }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold">{{ court.mapAlias }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold"> {{ court.mapFloor }}</v-col>
                <v-col cols="5" align-self="center" class="my-4">
                  <v-btn width="300" color="primary" @click="openMap(court.mapName)">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-col>
                      <v-col align-self="center"> {{ court.mapName | trimLength }} </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-center text-decoration-underline">County Courts at Law</v-card-title>
            <v-card-text v-for="(court, i ) in getCourtroomsList('CC')" :key="i" class="pa-0">
              <v-row no-gutters>
                <v-col cols="3" align-self="center" class="font-weight-bold">{{ court.mapName }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold">{{ court.mapAlias }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold"> {{ court.mapFloor }}</v-col>
                <v-col cols="5" align-self="center" class="my-4">
                  <v-btn width="300" color="primary" @click="openMap(court.mapName)">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-col>
                      <v-col align-self="center"> {{ court.mapName | trimLength }} </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-center text-decoration-underline">Probate Court</v-card-title>
            <v-card-text v-for="(court, i ) in getCourtroomsList('PC')" :key="i" class="pa-0">
              <v-row no-gutters>
                <v-col cols="3" align-self="center" class="font-weight-bold">{{ court.mapName }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold">{{ court.mapAlias }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold"> {{ court.mapFloor }}</v-col>
                <v-col cols="5" align-self="center" class="my-4">
                  <v-btn width="300" color="primary" @click="openMap(court.mapName)">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-col>
                      <v-col align-self="center"> {{ court.mapName | trimLength }} </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-center text-decoration-underline">Child Support Court</v-card-title>
            <v-card-text v-for="(court, i ) in getCourtroomsList('CSC')" :key="i" class="pa-0">
              <v-row no-gutters>
                <v-col cols="3" align-self="center" class="font-weight-bold">{{ court.mapName }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold">{{ court.mapAlias }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold"> {{ court.mapFloor }}</v-col>
                <v-col cols="5" align-self="center" class="my-4">
                  <v-btn width="300" color="primary" @click="openMap(court.mapName)">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-col>
                      <v-col align-self="center"> {{ court.mapName | trimLength }} </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title class="d-flex align-center justify-center text-decoration-underline">Others</v-card-title>
            <v-card-text v-for="(court, i ) in getCourtroomsList('MISC')" :key="i" class="pa-0">
              <v-row no-gutters>
                <v-col cols="3" align-self="center" class="font-weight-bold">{{ court.mapName }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold">{{ court.mapAlias }}</v-col>
                <v-col cols="2" align-self="center" class="font-weight-bold"> {{ court.mapFloor }}</v-col>
                <v-col cols="5" align-self="center" class="my-4">
                  <v-btn width="300" color="primary" @click="openMap(court.mapName)">
                    <v-row no-gutters>
                      <v-col cols="1">
                        <v-icon>mdi-map-marker</v-icon>
                      </v-col>
                      <v-col align-self="center"> {{ court.mapName | trimLength }} </v-col>
                    </v-row>
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
      <v-col cols="5" align-self="center">
        <v-row no-gutters class="fill-height" align="center">
          <v-col cols="5" align="center">
            <v-card flat width="85%" height="275" color="primary" elevation="5"
              class="d-flex align-center justify-center mb-12"
              href="https://casesearch.epcounty.com/PublicAccess/Search.aspx?ID=100">
              <v-card-text class="justify-center white--text text-h5 font-weight-bold">Criminal Case
                Record</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="5" align="center">
            <v-card flat width="85%" height="275" color="primary" elevation="5"
              class="d-flex align-center justify-center mb-12"
              href="https://casesearch.epcounty.com/PublicAccess/Search.aspx?ID=200">
              <v-card-text class="justify-center white--text text-h5 font-weight-bold">Civil, Family and Probate Case
                Record</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="5" align="center">
            <v-card flat color="primary" width="85%" height="275" elevation="5"
              class="d-flex align-center justify-center"
              href="https://casesearch.epcounty.com/PublicAccess/JailingSearch.aspx?ID=500">
              <v-card-text class="justify-center white--text text-h5 font-weight-bold">Jail Bond Records</v-card-text>
            </v-card>
          </v-col>
          <v-col cols="5" align="center">
            <v-card flat width="85%" height="275" color="primary" elevation="5"
              class="d-flex align-center justify-center"
              href="https://casesearch.epcounty.com/PublicAccess/JailingSearch.aspx?ID=400">
              <v-card-text class="justify-center white--text text-h5 font-weight-bold">Jail Records</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SimpleKeyboard from "@/components/SimpleKeyboard";
import moment from "moment";
import QrcodeVue from 'qrcode.vue';
import axios from "axios";
export default {
  name: "todayshearing",
  data() {
    return {
      caseNumber: "",
      size: 200,
      expand: true
    };
  },
  components: {
    SimpleKeyboard,
    QrcodeVue
  },
  computed: {

  },
  methods: {
    onChange(input) {
      this.caseNumber = input;
    },
    onKeyPress(button) {
      if (button === "{bksp}")
        this.caseNumber = this.caseNumber.slice(0, -1);
    },
    openMap(mapName) {
      let selectedMap = this.$i18n.t('maps').filter(map => map.mapName.toLowerCase() === mapName.toLowerCase())
      console.log('Selected Map', selectedMap)
      this.$store.dispatch('openImageViewer', {
        name: selectedMap[0].mapName,
        url: selectedMap[0].mapImage
      })
      this.$store.dispatch('avatarTalk', selectedMap[0].speech);
    },
    getCourtroomsList(courtType) {
      console.log('Map list: ', this.$i18n.t('maps').filter(map => map.isCourtroom && map.courtroomType === courtType))
      let courtroomList = this.$i18n.t('maps').filter(map => map.isCourtroom && map.courtroomType === courtType)
      return courtroomList.sort((a, b) => a.displayPosition - b.displayPosition)
    },
    searchByNumber() {
      // implement code here
      // call the API here
      const qs = require('qs');
      let data = qs.stringify({
        'siteKey': 'TXELPASOPROD2017',
        'odysseyMessageXML': '<Message MessageType="FindCaseByCaseNumber" NodeID="1"  ReferenceNumber="ARS-1" UserID="1" Source="Tyler">  <CaseNumber>20230D02275</CaseNumber></Message>'
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://odyintegration/webservices/apiwebservice.asmx/OdysseyMsgExecution',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });



      // const parser = new DOMParser();
      // const xmlDoc = parser.parseFromString(xmlOutputString, 'text/xml');

      // const nodeID = xmlDoc.querySelector('NodeID').textContent;
      // const caseID = xmlDoc.querySelector('CaseID').textContent;
      // const caseNumber = xmlDoc.querySelector('CaseNumber').textContent;
      // const caseStyle = xmlDoc.querySelector('CaseStyle').textContent;
      // const caseStatus = xmlDoc.querySelector('CaseStatus').textContent;
      // const caseType = xmlDoc.querySelector('CaseType').textContent;
      // const caseSecurityGroup = xmlDoc.querySelector('CaseSecurityGroup').textContent;

      // console.log('NodeID:', nodeID);
      // console.log('CaseID:', caseID);
      // console.log('CaseNumber:', caseNumber);
      // console.log('CaseStyle:', caseStyle);
      // console.log('CaseStatus:', caseStatus);
      // console.log('CaseType:', caseType);
      // console.log('CaseSecurityGroup:', caseSecurityGroup);
    },
  },
  mounted() {
    //
  },
  filters: {
    formatStringDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LL");
    },
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
    trimLength(val) {
      if (val.length < 25) {
        return val;
      }
      return `${val.substring(0, 20)}...`;
    }
  },
};
</script>

<style>
.cardCourtroom {
  border: solid #2c6b87 !important;
  ;
}
</style>

<i18n>
{
  "en": {
      "searchcaseno": "Search by case number",
      "searchcasename": "Search by name",
      "firstnamelabel": "First Name",
      "middlenamelabel": "Middle Name",
      "lastnamelabel": "Last Name",
      "casenolabel": "Case Number",
      "casenobutton": "Case Number Search",
      "searchnamebutton": "Search by Name",
      "casenoplaceholder": "Tap to enter case number. E.g. D-xx-xxx-xxxx-xxxxx"
    },
  "es":{
      "searchcaseno": "Buscar por número de caso",
      "searchcasename": "Buscar por nombre",
      "firstnamelabel": "Primer nombre",
      "middlenamelabel": "Segundo nombre",
      "lastnamelabel": "Apellido",
      "casenolabel": "Número de caso",
      "casenobutton": "Número de caso",
      "searchnamebutton": "Buscar por nombre",
      "casenoplaceholder": "Toque para ingresar el número de caso. P.ej. D-xx-xxx-xxxx-xxxxx"
    }
}
</i18n>
