import gql from 'graphql-tag'

//---------------------------- HomePage Queries ---------------------------------

export const homepageQuery = gql`
query homeQuery($alias : JSON, $lang: String) {
  homepages (sort: "displayPosition:asc", where : { alias : $alias }, locale: $lang) {
    id
    speech    
    pageName
    locale 
    alias    
    pageType{
      ... on ComponentHomepageNavigation{
        __typename
        pathName
        pageHeader
      }
      ... on ComponentHomepageDialogBox{
        __typename
        textList      
      }
    }
    displayIcon{
      id
      url
    }
  }
}
`

//---------------------------- Faq Page Queries ---------------------------------

export const faqCategoryQuery = gql`
query faqCategoryQuery($lang: String, $location: String, $category: String) {
  faqCategories(sort: "displayOrder:asc", locale: $lang, where : { courtLocationCode_contains : $location, categoryName : $category}) {
    id
    categoryName
    displayType
    displayOrder
    locale
  }
}
`

export const faqQuery = gql`
query faqQuery($lang: String) {
  faqs (start: 0, locale: $lang) {
    id
    category
    locale
    keyword
    showMap
    mapName
    showform
    formName
    showQr
    qrDetails
    linkID
    question
    answer
  }
}
`

export const faqQuery2 = gql`
query faqQuery($lang: String) {
  faqs (start: 100, locale: $lang) {
    id
    category
    locale
    keyword
    showMap
    mapName
    showform
    formName
    showQr
    qrDetails
    linkID
    question
    answer
  }
}
`
//---------------------------- Map Page Queries ---------------------------------

export const floorQuery = gql`
query floorQuery($lang: String, $location: String) {
  floors (sort : "floorNumber:ASC", locale: $lang, where : { kioskLocationCode_contains: $location }){ 
    id
    floorName
    floorNumber
    speech
    defaultLocation
    locale
    showDepartment
    floorImage {
      id
      url
    }
    department{
      ... on ComponentDepartmentDepartmentList{
        departmentName
        object
      }
    }
  }
}
`

export const mapQuery = gql`
query mapQuery($lang: String,  $location: String) {
  maps(sort: "mapFloor:ASC", locale: $lang, where : { kioskLocationCode_contains: $location }){
    id
    mapName
    mapFloor
    speech
    isCourtroom
    mapAlias
    courtroomType
    displayPosition
    locale
    mapImage {
      id
      url
    }
  }
}
`

//---------------------------- Form Page Queries ---------------------------------


export const formCategoryQuery = gql`
query formCategoryQuery ($lang: String) {
  formCategories(sort: "id:asc", locale: $lang) {
    id
    name
    categoryType{
      ... on ComponentFormCategoryTypeCategoryType{
        id
        type
      }
    }
    locale
    categoryId
  }
}
`

export const allFormQuery = gql`
query formQuery ($lang: String) {
  forms(start: 0, sort: "id:asc", locale: $lang) {
    id
    formName
    formCategory
    documentType
    documentUrl
    locale
    document{
      id
      url
    }
  }
}
`
//--------------------------------- Department -------------------------

export const departmentQuery = gql`
query departmentQuery($lang: String) {
  departments (locale: $lang) {
    id
		departmentName
    departmentMap
    departmentServices
    locale
  }
}`